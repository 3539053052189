import { Button } from 'components/Button';
import { FormEventHandler, InputHTMLAttributes, ReactNode } from 'react';
import { useField } from 'formik';
import styles from './Form.module.css';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';

interface FormProperties {
  buttonLabel: string;
  children: ReactNode;
  disabled?: boolean;
  error?: string;
  loading?: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
}

interface InputContainerProperties {
  children: ReactNode;
  description?: string;
  error?: string;
  label?: string;
}

interface InputProperties extends Omit<InputContainerProperties, 'children'>, InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

interface TextareaProperties extends Omit<InputContainerProperties, 'children'>, TextareaAutosizeProps {
  name: string;
}

export const Form = ({ buttonLabel, children, disabled = false, error, loading = false, onSubmit }: FormProperties) => (
  <form className={styles.form} onSubmit={onSubmit}>
    {children}
    <Button disabled={disabled || loading} loading={loading}>
      {buttonLabel}
    </Button>
    {error && <div className={styles.error}>{error}</div>}
  </form>
);

export const Input = ({ description, label, type = 'text', ...rest }: InputProperties) => {
  const [field, meta] = useField(rest);

  return (
    <InputContainer description={description} error={meta.touched ? meta.error : undefined} label={label}>
      <input className={styles.input} type={type} {...field} {...rest} />
    </InputContainer>
  );
};

export const TextArea = ({ description, label, ...rest }: TextareaProperties) => {
  const [field, meta] = useField(rest);

  return (
    <InputContainer description={description} error={meta.touched ? meta.error : undefined} label={label}>
      <TextareaAutosize className={styles.input} {...field} {...rest} />
    </InputContainer>
  );
};

const InputContainer = ({ children, description, error, label }: InputContainerProperties) => (
  <label className={styles.inputContainer}>
    {label && <span className={styles.inputLabel}>{label}</span>}
    {children}
    {error && <span className={styles.inputError}>{error}</span>}
    {description && <span className={styles.inputDescription}>{description}</span>}
  </label>
);
