import { LoginForm } from 'components/LoginForm';
import { MaxWidth } from 'components/MaxWidth';
import { Page } from 'components/Page';
import { RichText } from 'components/RichText';
import { Section } from 'components/Section';
import Link from 'next/link';

const LoginPage = () => (
  <Page title="Login" needsNoAuth>
    <MaxWidth>
      <Section>
        <RichText>
          <h1>Login</h1>
        </RichText>
        <LoginForm />
        <RichText>
          <Link href="/forgot-password">Forgot your password?</Link>
        </RichText>
      </Section>
    </MaxWidth>
  </Page>
);

export default LoginPage;
