import { captureException } from 'utils/sentry';
import { Form, Input } from 'components/Form';
import { Formik, FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';
import { useUser } from 'components/UserProvider';
import * as Yup from 'yup';

interface FormValues {
  emailOrUserName: string;
  password: string;
}

const initialValues: FormValues = { emailOrUserName: '', password: '' };
const validationSchema = Yup.object({ emailOrUserName: Yup.string().required(), password: Yup.string().required() });

export const LoginForm = () => {
  const [error, setError] = useState<string>();
  const { loginUser } = useUser();

  const onSubmit = useCallback(
    async ({ emailOrUserName, password }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      try {
        setSubmitting(true);
        await loginUser(emailOrUserName, password);
      } catch (error) {
        captureException(error);
        setError('Something went wrong');
      } finally {
        setSubmitting(false);
      }
    },
    [loginUser]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ errors, handleSubmit, isSubmitting }) => (
        <Form
          buttonLabel="Login"
          disabled={Object.values(errors).length > 0}
          error={error}
          loading={isSubmitting}
          onSubmit={handleSubmit}
        >
          <Input
            autoComplete="off"
            autoCapitalize="off"
            label="Username or emailaddress"
            name="emailOrUserName"
            required
            type="text"
          />
          <Input autoComplete="off" autoCapitalize="off" label="Password" name="password" required type="password" />
        </Form>
      )}
    </Formik>
  );
};
